@if (shelfDisplayed) {
  <div class="shelf-container" [class.display]="isFirstLoading || books.length" #bookContainer>
    <div
      class="shelf-placeholder"
      [@fadeIn]
      [class.mobile]="appService.isMobile$ | async"
      [class.blue-theme]="theme === 'blue'"
      [class.visible]="isFirstLoading"></div>

    <div class="shelf" [class.mobile]="appService.isMobile$ | async" [class.display]="!isFirstLoading" [class.blue-theme]="theme === 'blue'">
      <div class="shelf-header" [routerLink]="shelfRoute" [class.blue-theme]="theme === 'blue'" [class.mobile]="appService.isMobile$ | async">
        <div class="title" [class.mobile]="appService.isMobile$ | async">{{ title || '-' }}</div>
        @if (appService.isMobile$ | async) {
          <img [src]="'assets/icon/' + (theme === 'blue' ? 'right-yellow-arrow.svg' : 'right-violet-arrow.svg')" alt="" />
        } @else {
          <div class="see-all" [routerLink]="shelfRoute">{{ 'PWA_desktop_libraryShelf_CTA_seeMore' | translate }}</div>
        }
      </div>

      @if ((appService.isMobile$ | async) === false && theme === 'white') {
        @if (swiper && swiper.activeIndex > 0) {
          <div class="left" [@fadeInOut]>
            <app-button [icon]="'assets/icon/left-arrow-bold.svg'" (clicked)="swipeBook(false)" [shape]="'circle'" [size]="'small'" />
          </div>
        }

        @if (swiper && (!swiper.isEnd || isLoadingNewPage || isEverythingLoaded)) {
          <div class="right" [@fadeInOut]>
            <app-button
              [icon]="'assets/icon/right-arrow-bold.svg'"
              (clicked)="swipeBook(true)"
              [shape]="'circle'"
              [size]="'small'"
              [disabled]="isLoadingNewPage" />
          </div>
        }
      }

      <swiper-container appSwiper [config]="swiperConfiguration" [class.desktop]="(appService.isMobile$ | async) === false">
        @defer (on viewport(bookContainer)) {
          @for (book of books; track book.title) {
            <swiper-slide>
              <app-book-shelf-cover-image [book]="book" [type]="type" [theme]="theme" />
            </swiper-slide>
          }
        }

        @if (isLoadingNewPage) {
          <div class="shelf-spinner">
            <ion-spinner name="circular"></ion-spinner>
          </div>
        }

        <!-- Needed in order to display the correct shelf placeholder height -->
        @if (isFirstLoading) {
          <swiper-slide>
            <app-book-shelf-cover-image [type]="type" [theme]="theme" />
          </swiper-slide>
        }

        @if (type === shelfType.Favorites && books.length === 1) {
          <div class="bean-prompt-container">
            <app-book-shelf-bean-prompt
              [beanLabel]="'PWA_myFavorites_buddyMessage_findFavorites' | translate"
              [buttonLabel]="'PWA_myFavorites_shelf_CTA_seeMore' | translate"
              (buttonClicked)="goToHome()" />
          </div>
        } @else if (type === shelfType.ContinueReading && books.length === 1) {
          <div class="bean-prompt-container">
            <app-book-shelf-bean-prompt
              [beanLabel]="'PWA_continueReading_buddyMessage_finishBook' | translate"
              [buttonLabel]="'PWA_continueReading_shelf_CTA_finishBook' | translate"
              (buttonClicked)="openNotFinishedBook()" />
          </div>
        } @else if (type === shelfType.ReadAgain && books.length === 1) {
          <div class="bean-prompt-container">
            <app-book-shelf-bean-prompt
              [beanLabel]="'PWA_readItAgain_buddyMessage_readMore' | translate"
              [buttonLabel]="'PWA_readItAgain_shelf_CTA_seeMore' | translate"
              (buttonClicked)="goToHome()" />
          </div>
        }
      </swiper-container>
    </div>
  </div>
}
